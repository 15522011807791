import styled from 'styled-components';
import { makeResponsive } from 'atomic-layout';

const Text = styled.div `

  color: ${({color}) => color ? color : '#0c1e2c'};
  font-weight: ${({weight}) => weight ? weight : '400'};
  line-height: ${({lineHeight}) => lineHeight ? lineHeight  : 1.3};
  font-size: ${({size}) => size ? size + 'px' : '20px'};
`;
export default makeResponsive( Text )