import React from 'react';

import Slider from "react-slick";

import QuoteSlide from './QuoteSlide';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

 const sampleData = [
   { text:'', author:''},
   { text:'', author:''}
 ]

 const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 9000,
  autoplay: true,
  pauseOnHover: true,
  arrows:false,
  draggable:true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots:false
       
      }
    }
  ]
};

const QuoteSlider = ({ data, centered }) =>{
  let slideData = data ? data : sampleData;

  return(
    <Slider {...settings}>
      {
        slideData.map( (slide, i ) =>(
            <QuoteSlide key={'slide_'+i} text={slide.text} author={slide.author} />
        ))
      }

    </Slider>
  )
}
export default QuoteSlider;