import React, { useState, useEffect, useRef } from 'react';
import { Link, navigate} from "@reach/router";
import { Composition } from 'atomic-layout';
import styled  from 'styled-components';
import Modal from "react-modal";
import { RiCloseFill } from 'react-icons/ri'

//Local
import { useOnClickOutside } from './hooks';
import { DeskTopMenu, Burger, MobileMenu } from './components/navmenus'
import { SignInForm } from './components/forms'
import { SignInButtonStyle, DemoButtonStyle } from './styledcomponents'
import logo from './assets/LoomLogoLightText.png';

const areasMobile = `
  logo mobileNav
  mobileDemo mobileDemo
`
const areasDesktop = `
  logo menu demo
`
const HeaderContainer = styled.div `
  background: ${({scroll}) =>  scroll ? '#0c1e2c': 'transparent'};
  transition: background 500ms ease;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 990;
  .logo{
    display: block;
    max-width: 175px;
    height: auto;
  }
`
const CloseButton = styled.button `
  border:0;
  background: transparent;
  color: #999;
  font-size: 36px;
  cursor: pointer;
`

Modal.setAppElement("#root");

const Header = () =>{

  const menuNode = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
   window.addEventListener("scroll", handleScroll);
   return () => window.removeEventListener("scroll", handleScroll )
  }, []);
 
  const handleScroll = () =>{
    setScroll(window.scrollY > 150);
  }
  
  const openSignIn = () =>{
    setIsOpen(true);
    setOpen(false);
  }

  const closeModal = () =>{
    setIsOpen(false);
  }

  const handleMobileLink = (to,state) =>{
    setOpen(false);
    navigate(to,state);
    
  }
  useOnClickOutside(menuNode, () => setOpen(false))
  return(
    <HeaderContainer className="Header" scroll={scroll}>
      <Composition
        areas={areasMobile}
        areasLg={areasDesktop}
        templateCols={'175px 1fr'}
        
        templateColsLg={'175px 3fr 230px'}
        gap={20}
        paddingHorizontal={20}
        paddingVertical={20}
        paddingHorizontalMd={40}
        paddingVerticalMd={25}
        paddingHorizontalLg={50}
        paddingVerticalLg={25}
      >
      {(Areas) =>(
        <>
          <Areas.Logo 
            flex
            alignItems={'center'}
          >
            <a 
              href="/" 
              title='Loominance Home'
            >
              <img className="logo" src={logo} alt={'Loominance Logo'} />
            </a>
          </Areas.Logo>
          <Areas.MobileNav
            ref={menuNode}
          >
            <Burger open={open} setOpen={setOpen} />
            <MobileMenu 
              open={open} 
              openSignIn={openSignIn} 
              handleMobileLink={handleMobileLink}
            />
          </Areas.MobileNav>
          <Areas.MobileDemo
            style={{margin: '0 auto'}}
          >
            <DemoButtonStyle
              className={'scroll-link'} 
              to="/" state={{ id:'contact'}} 
            >
              Request Demo
            </DemoButtonStyle>
          </Areas.MobileDemo>
          <Areas.Menu
            flex
            alignItems={'center'}
            justifyContent={'center'}
            justifyContentLg={'initial'}
            marginLeftMd={'auto'}
            templateCols={'1fr'}
          >
            <DeskTopMenu>
              <li className="dropdown">
                <Link 
                  className={'scroll-link dropbtn'} 
                  to="/" state={{ id:'syncro'}}
                >
                  Trends
                </Link> 
              
              </li>
              <li className="dropdown">
                <Link 
                  className={'scroll-link dropbtn'} 
                  to="/" state={{ id:'solution'}} 
                >
                  How We Solve
                </Link>
                
              </li> 
              <li>
                <Link 
                  className={'scroll-link'} 
                  to="/" state={{ id:'impact'}} 
                >
                  Customer Success
                </Link>
              </li>
            
            </DeskTopMenu> 
  
          </Areas.Menu>
          <Areas.Demo 
            flex
            alignItems={'center'}
            justifyContent={'center'}
          >
          <DemoButtonStyle
              className={'scroll-link'} 
              to="/" state={{ id:'contact'}} 
            >
              Request Demo
            </DemoButtonStyle>
            <SignInButtonStyle 
              onClick={openSignIn}
              style={{marginLeft:'auto'}}
            >
              Sign in
            </SignInButtonStyle>
              
          </Areas.Demo>
        </>
      )}
      </Composition>
      <Modal
        isOpen={isOpen}
        style={modalStyles}
        contentLabel="Sign In"
        closeTimeoutMS={600}
        shouldCloseOnOverlayClick={false}
      > 
        <div style={{padding:'30px', textAlign:'center' }}>
          <CloseButton onClick={closeModal}>
            <RiCloseFill />
          </CloseButton>
        </div>
        <SignInForm />
      </Modal>
    </HeaderContainer>
  )
}
export default Header;

//Modal style
const modalStyles = {
  overlay:{
    zIndex                : '1000',
    transition: 'all 300ms ease-out',
    background:'linear-gradient(58deg, rgba(255,255,255,1) 25%, rgba(238,238,238,1) 100%)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
   
    border                : 'none',
    overflow              : 'inherit',
    maxHeight             : '85vh',
    maxWidth              : '90vw',
    background: 'transparent'
  }
};

/**
 * 
 * <DeskTopMenu>
              <li className="dropdown">
                <Link 
                  className={'scroll-link dropbtn'} 
                  to="/" state={{ id:'syncro'}}
                >
                  Trends
                </Link> 
                <div className="dropdown-content">
                  <a href="/">Current Landscape</a>
                  <a href="/">Way Forward</a>
                </div>
              </li>
              <li className="dropdown">
                <Link 
                  className={'scroll-link dropbtn'} 
                  to="/" state={{ id:'solution'}} 
                >
                  How We Solve
                </Link>
                <div className="dropdown-content">
                  <a href="/">Modern Cloud</a>
                  <a href="/">Old Way / Modern Way</a>
                  <a href="/">Pipeline Anatomy</a>
                </div>
              </li> 
             
              <li className="dropdown">
                <Link 
                  className={'scroll-link dropbtn'} 
                  to="/company" state={{ id:'solution'}} 
                >
                  Company
                </Link>
                <div className="dropdown-content">
                  <Link  to="/company">About Us</Link>
                  <a href="/team">The Team</a>
                </div>
              </li> 
              <li>
                <Link 
                  className={'scroll-link'} 
                  to="/" state={{ id:'impact'}} 
                >
                  Customer Success
                </Link>
              </li>
            </DeskTopMenu> 
 */