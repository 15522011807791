import React from 'react';
import { Composition } from 'atomic-layout';

import { 
  Section, 
  ContentHeading, 
  ContentSubHeading ,
  Card,
  Text
} from '../styledcomponents'

const areas= `
  smallHeading
  bigHeading
  cards
  text
`;

const screenBG = 'https://loominance.com/assets/images/cover-background_03.jpg';

const cardData = [
  { title:'Changed Environment', text:'Environment has changed dramatically, where customers do research in places where companies cannot track.'},
  { title:'Dark Funnel', text: <React.Fragment><strong>"Dark Funnel"</strong> popularized by <strong>Intent Data Providers</strong> to activate outbound sales.</React.Fragment>},
  { title:'Opportunity', text:<React.Fragment>Opportunity for <strong>Marketers</strong> to lean in, understand how to use these new strategies and capabilities.</React.Fragment> }
]

const cardData2 = [
  
  { text: 'Buyers are increasingly leaving relevant signals across the web.'},
  { text: 'Requires fundamentally different approach and this is an opportunity for marketing.' },
  { text: <React.Fragment>And for best success, <strong>a new approach needs to be aligned across marketing sales, ops, channel and IT</strong>.</React.Fragment> }
]
const TrendJourney = ({children}) =>{

  return(
    <Section
      id=""
      bgcolor={'#fff'}
      url={screenBG}
    >
      <Composition
        className={'content'}
        areas={areas}
        gap={0}
        templateCols={'1fr'}
        padding={30} 
        paddingBottom={45}
        paddingSm={35} 
        paddingMd={45} 
        paddingVerticalMd={90}
        paddingHorizontalLg={80}
        paddingVerticalLg={120}
      >
        {
          (Areas)=>(
            <>
              <Areas.SmallHeading>
                <ContentHeading
                  size={16}
                  sizeMd={20}
                  sizeLg={24}
                  weight={'400'}
                  transform={'uppercase'}
                >
                  Trends
                </ContentHeading>
              </Areas.SmallHeading>
              <Areas.BigHeading
                 widthLg={800}
              >
                <ContentSubHeading
                  size={30}
                  sizeSm={30}
                  sizeMd={40}
                  sizeLg={45}
                  sizeXl={55}
                  marginBottom={20}
                  marginBottomMd={40}
                  color={'#0c1e2c'}
                >
                  80% of a buyer’s journey is done before vendors are selected.
                </ContentSubHeading>
              </Areas.BigHeading>
              <Areas.Cards>
                <Composition
                  templateCols="1fr"
                  templateColsMd="repeat(2, 1fr)"
                  templateColsLg="repeat(2, 1fr)"
                  templateColsXl="repeat(3, 1fr)"
                  justifyContent="center"
                  gutter={20}
                  gutterLg={30}
                >
                    {
                      cardData.map( (data, index) => (
                        <Card key={data.title + '_' + index}>
                          <Composition
                            padding={20}
                            paddingVerticalMd={35}
                            paddingHorizontalMd={35}
                          >
                            <ContentSubHeading
                              size={20}
                              sizeSm={24}
                              sizeMd={28}
                              marginBottom={20}
                              color={'#a1e9fe'}
                            >
                              {data.title}
                            </ContentSubHeading>
                            <Text
                              size={16}
                              sizeSm={16}
                              lineHeight={1.6}
                              color={'#fff'}
                            >
                              { data.text }
                            </Text>
                          </Composition>
                        </Card>
                        
                      ) )
                    }
                    {
                      cardData2.map( (data, index) => (
                        <Card 
                          key={data.title + '_' + index}
                          bgcolor={'#f9f9f9'}
                          boxShadow
                        >
                          <Composition
                            padding={20}
                            paddingVerticalMd={35}
                            paddingHorizontalMd={35}
                          >
                            <Text
                              size={16}
                              sizeSm={16}
                              lineHeight={1.6}
                              color={'#0e1e2c'}
                            >
                              { data.text }
                            </Text>
                          </Composition>
                        </Card>
                      ))
                    }
                </Composition>   
              </Areas.Cards>
            </>
          )
        }
      </Composition>
    </Section>
  )
}

export default TrendJourney;