import React,{ useEffect} from 'react';
import Scroll from 'react-scroll';

//Local App
import { useMediaQuery } from '../hooks'
import BasePage from './BasePage'

import { 
  ScreenOne, 
  AreYouSyncro,
  SingleQuote,
  TrustAlignment,
  Solution,
  Impact,
  Contact,
  TrendJourney,
  ElephantRoom,
  Challenge,
  AbmStrategy
} from '../screens';

const scroller = Scroll.scroller;

const HomePage = ({location}) => {
  //
  const isMobileSm = useMediaQuery('(min-width: 500px)');
  const isMobileMd = useMediaQuery('(min-width: 769px)');
  
  useEffect(()=>{
    let id = location.state ? location.state.id : null
    window.history.replaceState({}, document.title)
    if(id){
       setTimeout(() => {
        scroller.scrollTo(id, { smooth:true, offset: isMobileSm && !isMobileMd ? -128  : -118  }  )
      }, 600);
    }
      return () => null
  },[location,isMobileSm,isMobileMd])
  return (
    <BasePage>
      <ScreenOne />
      <AreYouSyncro />
      <SingleQuote />
      <TrustAlignment />
      <TrendJourney />
      <ElephantRoom />
      <Challenge />
      <AbmStrategy />
      <Solution />
      <Impact />
      <Contact />
    </BasePage>
  );
}

export default HomePage;