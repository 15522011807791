import styled from 'styled-components'
import { makeResponsive } from 'atomic-layout';

const ResponsiveImage = styled.img`
  display: block;
  border-radius: 4px;
  margin: ${({ center, margin }) => center ? '0 auto' : margin ? margin : 'initial'};
  max-width:  ${({ maxWidth }) => maxWidth ? maxWidth + 'px': 'initial'};
  height: auto;
`

export default makeResponsive(ResponsiveImage);