import styled from 'styled-components';
import { makeResponsive } from 'atomic-layout';

const Paragraph = styled.p `

  color: ${({color}) => color ? color : '#0c1e2c'};
  font-weight: ${({weight}) => weight ? weight : '400'};
  line-height: ${({lineHeight}) => lineHeight ? lineHeight  : 1.4};
  font-size: ${({size}) => size ? size + 'px' : '20px'};
  margin-top: ${({marginTop}) => marginTop ? marginTop + 'px' : '20px'};
  margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom+ 'px' : '20px'};
`;
export default makeResponsive( Paragraph )