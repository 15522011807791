import styled from 'styled-components';
import { makeResponsive } from 'atomic-layout';

const ContentSubHeading =  styled.h2 `
  margin: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom + 'px': 'initial'};
  font-weight: ${({ weight }) => weight ? weight: '700'};
  font-size: ${({ size }) => size ? size: '18'}px;
  color: ${({ color }) => color ? color: '#0c1e2c'};
`;

export default makeResponsive(ContentSubHeading);