import React from 'react';
import { Composition } from 'atomic-layout';

import TextInCircle from '../components/TextInCircle'
import { Section, Text, ContentHeading, ContentSubHeading }  from '../styledcomponents';

const areasMobile = `
  circleOne
  circleTwo
  circleThree
`
const areasTablet = `
  circleOne circleTwo circleThree
`

const areasTextBox = `
  text
`
const areasTextBoxLg = `
  . text .
`
const screenBG = 'https://loominance.com/assets/images/cover-background_01.jpg';
const  TrustAlignment = () =>{
  return(
    <Section
      bgcolor={'#eee'}
      url={screenBG}
    >
    <Composition
    className={'content'}
    templateCols={' 1fr'}
    padding={30} 
    paddingVertical={50}
    paddingSm={35} 
    paddingVerticalSm={60}
    paddingMd={45} 
    paddingVerticalMd={90}
    paddingHorizontalLg={80}
    paddingVerticalLg={120}
    >
      <ContentHeading
        color={'#fff'}
        size={16}
        sizeMd={20}
        sizeLg={24}
        weight={'400'}
        transform={'uppercase'}
      >
      Trends
      </ContentHeading>
      <ContentSubHeading
        size={30}
        sizeSm={30}
        sizeMd={40}
        sizeLg={45}
        sizeXl={55}
        color={'#fff'}
      >
        Trust Alignment.
      </ContentSubHeading>
    </Composition>
      <Composition
        className={'content'}
        areas={areasMobile}
        areasMd={areasTablet}
        templateCols={'repeat(1, 1fr)'}
        templateColsMd={'repeat(3, 1fr)'}
        gap={20}
        gapMd={30}
        padding={30} 
        paddingVertical={50}
        paddingSm={35} 
        paddingVerticalSm={60}
        paddingMd={45} 
        paddingBottomMd={90}
        paddingHorizontalLg={80}
      
      
      >
      { ({ CircleOne, CircleTwo, CircleThree}) =>(
          <>
            <CircleOne
             flex
              justifyContent={'center'}
              alignItems={'center'}
            >
              <TextInCircle text="Account Centricity" />
            </CircleOne>
            <CircleTwo
              flex
              justifyContent={'center'}
              alignItems={'center'}
            >
              <TextInCircle text="Increase in Data Volumes and Sources" />
            </CircleTwo>
            <CircleThree
              flex
              justifyContent={'center'}
              alignItems={'center'}
            >
              <TextInCircle text="Intelligent Automation Required" />
            </CircleThree>
            
          </>
        )
      }
      </Composition>
      <Composition
        areas={areasTextBox}
        areasMd={areasTextBoxLg}
        templateCols={'1fr'}
        templateColsMd={'1fr 2fr 1fr'}
        paddingHorizontal={30}
        paddingBottom={50}
        paddingMd={45} 
        paddingBottomMd={120}
      >
      {
        (Areas) => (
          <>
            <Areas.Text
              style={{
                background:'rgb(255 255 255 / 15%)',
                position: 'relative',
              }}
                padding={20}
                paddingMd={20}
            >
              <Text
                color={'#fff'}
                weight={600}
                size={22}
                sizeLg={24}
                sizeXl={28}
                style={{ textAlign:'center'}}
              >
                Must build trust and alignment across the organization
              </Text>
            </Areas.Text> 
          </>
        )
      }
      </Composition>
    </Section>
  )
}
export default TrustAlignment;