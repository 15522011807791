import React from 'react'
import { Composition } from 'atomic-layout'
import LoomFeature from './LoomFeature'

const LoomFeatureList = ({ data }) => (
  <Composition
    templateCols="1fr"
    templateColsMd="repeat(2, 1fr)"
    templateColsLg="repeat(2, 1fr)"
    templateColsXl="repeat(3, 1fr)"
    justifyContent="center"
    gutter={20}
    gutterLg={30}
  >
    {data.map((item) => (
      <LoomFeature key={item.id} data={item} />
    ))}
  </Composition>
)

export default LoomFeatureList;