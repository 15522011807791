import styled from 'styled-components';

const SignInButtonStyle = styled.button `
  display: inline-flex; 
  align-self: center;
  font-size: 14px; 
  font-family: inherit;
  padding: 12px 8px;
  border-radius: 50px;
  border: 0;
  cursor: pointer;
  background: transparent;
  color: #fff;
  :hover{
    color: #0cf
  }
`
export default SignInButtonStyle;

