import styled from 'styled-components';
import { makeResponsive } from 'atomic-layout';

const UL = styled.ul ` 
  padding-left: ${({ paddingLeft }) => paddingLeft ? paddingLeft + 'px': '25px'};
  margin-top: ${({ marginTop }) => marginTop ? marginTop + 'px': 'initial'};
  & >li{
    margin-bottom: 10px
  }
`

export default makeResponsive(UL);