import styled from 'styled-components';
import { makeResponsive } from 'atomic-layout';
const InvisiButton = styled.div `
 display: block;
  border: 0;
  background: transparent;
  padding: 2px;
  font-size: ${({ size }) => size ? size: '32'}px;
  color: ${({ color }) => color ? color: '#0c1e2c'};
  > a{
    cursor:pointer;
  }
`
export default makeResponsive(InvisiButton);