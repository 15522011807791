import styled from 'styled-components';
import { makeResponsive } from 'atomic-layout';

const LargeSubHeading =  styled.h2 `
  margin: 0;
  line-height: ${({ lineHeight }) => lineHeight ? lineHeight: 1.1};
  font-size: ${({ size }) => size ? size: '18'}px;
  color: ${({ color }) => color ? color: '#333'};
  background-color: ${({ bgcolor }) => bgcolor ? bgcolor: 'transparent'};
  &.fatPad{
    padding: ${({ pad }) => pad ? pad: '20'}px;
    display:inline-flex;
  }
`;

export default makeResponsive(LargeSubHeading);