import React from 'react';
import { Composition } from 'atomic-layout'

import { Card, Text, Image } from '../../styledcomponents'

const areasMobile = `
thumbnail
content
`

const LoomFeature = ({ data }) => (
  <Card
  bgcolor={ data.imageUrl ? 'transparent' : 'rgb(255 255 255 / 6%)' }
  >
    <Composition
      areas={areasMobile}
     
      areasLg={areasMobile}
     
      gutter={10}
      gutterMd={20}
      padding={20}
      paddingVerticalMd={25}
      paddingHorizontalMd={35}
    >
      {({ Thumbnail, Content }) => (
        <>
        <Thumbnail
        
        >
            { 
              data.imageUrl ?  <Image style={{ margin:'auto auto'}} src={data.imageUrl} alt={'image_'+data.id} />  
              :  
              <Text
              color={'#a1e9ff'}
              size={20}
              sizeSm={24}
              weight={600}
              >{data.id}</Text> 
            } 
        </Thumbnail>
        <Content>
          <Text
            color={'#fff'}
            size={16}
            sizeSm={16}
            lineHeight={1.6}
          >{data.text}</Text>
        </Content>
        
        </>
      )}
    </Composition>
  </Card>
)

export default LoomFeature
