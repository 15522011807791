import styled from 'styled-components';
import { Link } from "@reach/router";
const DemoButtonStyle = styled(Link) `
  display: inline-flex; 
  align-self: center;
  font-size: 14px; 
  font-family: inherit;
  padding: 0.6em 1.2em !important;
  margin-left: 0.8em;
  border-radius: 50px;
  border: 0;
  cursor: pointer;
  background-color: #f63a7a;
  color: #fff;
  :hover{
    background:#ccc !important;
    color: #0c1e2c !important
  }
`
export default DemoButtonStyle;