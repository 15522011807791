import React from 'react';
import { Composition, Box } from 'atomic-layout';

import { QuoteSlider } from '../components/sliders'
import { ContentHeading, Section}  from '../styledcomponents';

const areasSlide2Mobile = `
  headings
  footer
`;

//const screenBG = 'https://loominance.com/assets/images/cover-background_02.jpg';
const quotes = [
  { 
    text:'We have never had this level of visibility before. We were able to directly increase engagement by updating our content on the fly based on seeing what the account appeared to be looking for in the market.', 
    author:'Director, North America Retail Marketing, Leading Security Enterprise'
  },
  {
    text:'91% of sales reps wanted top 25 alerts for future programs, 73% of sales reps said the account based data directly changed or improved the way they approached the account.',
    author: 'Leading Networking Enterprise Provider'
  },
  { 
    text:'This is perfect. This is exactly what we need to do to scale the sales process. We want sales reps hunting not researching which accounts to target.', 
    author:'Principal Americas Marketing Lead, Security Division'
  },
  {
    text:'"Not only has Loominance yielded better conversion rates, but this data has encouraged senior sales reps who were initially closed off to call blitz days to participate since they can better understand how to approach a cross-sell conversation with their existing customers."',
    author: 'Senior Global Partner Go-to-Market Manager'
  }
]
const Impact = ({children}) =>{
  return(
    <React.Fragment>
      <Section 
        id={'impact'}
        className="section"
        style={{  position: 'relative', zIndex: 5, transformStyle:'preserve-3d' }}
      >
        <Composition
          className={'content'}
          areas={areasSlide2Mobile}
          areasMd={areasSlide2Mobile}
          gap={0}
          gapMd={0}
          gapRow={0}
        >
          {(Areas) => (
            <>
              
              <Areas.Headings 
                paddingVertical={45} 
                paddingHorizontal={30}
                paddingVerticalSm={55} 
                paddingHorizontalSm={35}
                paddingMd={65} 
                paddingTopMd={90}
                paddingHorizontalLg={80}
                paddingTopLg={120}
              >
                <ContentHeading
                  size={30}
                  sizeSm={30}
                  sizeMd={40}
                  sizeLg={45}
                  sizeXl={55}
                  color={'#0c1e2c'}
                  weight={'700'}
                
                  style={{ textAlign: 'center'}}
                >
                  Customer Success
                </ContentHeading>
                {/*<ContentSubHeading
                  size={30}
                  sizeSm={30}
                  sizeMd={40}
                  sizeLg={45}
                  sizeXl={55}
                  color={'#0c1e2c'}
               
                >Impact on our Customers
                </ContentSubHeading>*/}
              </Areas.Headings>
            </>
          )}
        </Composition>
        <Box
          paddingTop={0} 
          paddingHorizontal={30}
          paddingBottom={80}
          paddingSm={35} 
          paddingBottomSm={60}
          paddingTopSm={0}
          paddingMd={45} 
          paddingBottomMd={90}
          paddingHorizontalLg={80}
          paddingBottomLg={120}
          style={{maxWidth:'1000px', margin:'0 auto'}}
        >
          <QuoteSlider data={quotes} />
        </Box>
      </Section>
     
    </React.Fragment>
  )
}

export default Impact