import React from 'react';
import styled from 'styled-components';
import { Text } from '../styledcomponents'
import { makeResponsive  } from 'atomic-layout';

const CircleBase =styled.div `
  max-width:  ${({ maxWidth }) => maxWidth ? maxWidth + 'px': '300px'};
  width: 100%;
  position: relative;
  display:block;
  background-color: transparent;
  color: #222;
  text-align: center;
  border:  23px solid rgb(58 160 247 / 40%);
    border-radius: 100%;
  &:after {
    display: block;
    padding-bottom: 100%;
    width: 100%;
    height: 0;
    border-radius: 510%;
    background-color: #4082d8;
    content: "";
  }
`;
const Circle = makeResponsive(CircleBase)

const CircleInner =styled.div `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const CircleWrapper = styled.div `
  display: table;
  width: 100%;
  height: 100%;
`
const CircleContent = styled.div `
  display: table-cell;
  padding: 2em;
  vertical-align: middle;
`
const TextInCircle = ({text}) =>{
  return(
    <Circle
      maxWidth={250}
      maxWidthMd={300}
    >
      <CircleInner>
        <CircleWrapper>
          <CircleContent>
            <Text
              size={18}
              sizeSm={18}
              sizeLg={24}
              color={'#fff'}
              weight={600}
              lineHeight={1.1}
            >
              {text}
            </Text>
              
          </CircleContent>
        </CircleWrapper>
      </CircleInner>
    </Circle>
  )
}

export default TextInCircle;