import React from 'react';
import { Composition } from 'atomic-layout';

import { 
  Section, 
  Card,
  ContentHeading, 
  ContentSubHeading, 
  ResponsiveImage,
  UL,
  Paragraph
} from '../styledcomponents'

const areas = `
  headings
  content
  cards
  end
`;
const areasEnd= `
  block3
  block4
`
const areasEndDeskTop= `
  block3 block4
`
const areasContent = `
  block1
  block2
`
const areasContentDeskTop = `
  block1 block2
` 
//const screenBG = 'https://loominance.com/assets/images/cover-background_03.jpg';
const qualifyAccountsImage = 'https://loominance.com/assets/images/qualify-accounts.png';

const cardData = [
  { 
    title:'What is the account "fit"?', 
    text:[
      'Firmographic alignment emdash does this account meet basic location, vertical, and company size requirements for your products and services?',
      'Can the existing sales motion support this account?'
    ] 
  },
  { 
    title:'Is the account showing validated buying "intent"?', 
    text:[
      'Buying signals - is the account showing buying signals for specific products and services you sell?',
      'Are these accounts interested in your company’s or competitor\'s offering?'
    ] 
  },
  { 
    title:'Is the account showing "engagement"?', 
    text:[
      'Engagement and contacts - "who is who" in the account? Do you have contacts directly engaged with your content or do you need to find new contacts to target for engagement?',
      'Is the account looking to buy now? Or just looking around? Which account do I go after first to increase the sales close rate?'
    ] 
  },
]


const AbmStrategy = () =>{
  return(
    <Section
      bgcolor={'#fff'}
      
      style={{ borderTop:'1px solid #eee'}}
    >
      <Composition
        className={'content'}
       
        areas={areas}
        templateCols={'1fr'}    
        padding={30} 
        paddingBottom={45}
        paddingSm={35} 
        paddingMd={45} 
        paddingVerticalMd={90}
        paddingHorizontalLg={80}
        paddingVerticalLg={120}
      >
        {
          (Areas) =>(
            <>
              <Areas.Headings
                 maxWidth={860}
              >
                <ContentHeading
                  size={16}
                  sizeMd={20}
                  sizeLg={24}
                  weight={'400'}
                  transform={'uppercase'}
                  color={'#0c1e2c'}
                >
                  Way Forward
                </ContentHeading>
                <ContentSubHeading
                  
                  size={30}
                  sizeSm={30}
                  sizeMd={40}
                  sizeLg={45}
                  sizeXl={55}
                  marginBottom={20}
                  marginBottomMd={40}
           
                >
                  The way forward is <br />qualifying accounts not leads.
                </ContentSubHeading>
              </Areas.Headings>
              <Areas.Content>
                <Composition
                  areas={areasContent}
                  areasLg={areasContentDeskTop}
                  templateCols={'1fr'}
                  templateColsLg={'2.5fr 2.5fr'}
                  style={{
                    background:'url(https://loominance.com/assets/images/cover-background_01.jpg)',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover',
                    boxShadow: 'rgb(12 30 44 / 15%) 15px 20px 40px -15px'
                  }}
                >
                    {
                      (Areas) => (
                        <>
                          <Areas.Block1
                            style={{ background:'#f9f9f9'}}
                            padding={30}
                            paddingMd={40}
                            paddingLg={45}
                            flex
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                          <div style={{ maxWidth:'370px'}}>
                            <Paragraph>
                              <strong>ABM is a strategy not a platform.</strong> To execute on ABM strategy, there are a number of key questions marketers need to ask before any other decisions can be  made.
                            </Paragraph>
                            </div>
                          </Areas.Block1>
                          <Areas.Block2
                            padding={30}
                            paddingMd={40}
                            paddingLg={45}
                            style={{ backgroundColor:'rgba(255, 255, 255, 0.1)'}}
                          >  
                               <ResponsiveImage 
                                center  
                                maxWidth={250}
                                maxWidthMd={275}
                                maxWidthLg={325}
                                src={qualifyAccountsImage} alt={'Qualifying Acount Diagram'}
                                />
                           </Areas.Block2>
                        </>
                      )
                    }
                </Composition>
              </Areas.Content>
              <Areas.Cards>
                <Composition
                  paddingVerticalTop={40}
                  paddingVerticalBottom={0}
                  paddingVerticalBottomMd={20}
                  paddingTopLg={90}
                  paddingBottomLg={40}
                  templateCols="1fr"
                  templateColsMd="repeat(2, 1fr)"
                  templateColsLg="repeat(2, 1fr)"
                  templateColsXl="repeat(3, 1fr)"
                  justifyContent="center"
                  gutter={20}
                  gutterLg={30}
                >
                  {
                    cardData.map((data, index)=>(
                      <Card
                      key={'card_'+index}
                      bgcolor={'transparent'}
                      color={'#0c1e2c'}
                      >
                        <Composition
                          padding={20}
                         
                          paddingHorizontalMd={35}
                        >
                          <ContentSubHeading
                            size={18}
                            sizeSm={20}
                            sizeMd={22}
                            marginBottom={20}
                            color={'#0c1e2c'}
                            style={
                              { 
                                height: '45px',
                                display: 'flex',
                                alignItems: 'flex-end'
                              }
                            }
                          >
                            {data.title}
                        </ContentSubHeading>
                          {
                            Array.isArray(data.text) ? 
                              <UL>
                                {
                                  data.text.map((text,index)=>(<li key={'text-'+index}>{text}</li>))
                                }
                              </UL>
                            : <Paragraph>{data.text}</Paragraph>
                          }
                        </Composition>
                      </Card>
                    ))
                  }
                </Composition>
              </Areas.Cards>
              <Areas.End>
                <Composition
                  areas={areasEnd}
                  areasLg={areasEndDeskTop}
                  templateCols={'1fr'}
                  templateColsLg={'2.5fr 2.5fr'}
                  gutter={20}
                  gutterLg={30}
                >
                  {
                    (Areas) =>(
                      <>
                        <Areas.Block3
                          padding={30}
                          
                          flex
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          <Paragraph>
                            In the Loominance approach, we are the “<strong>always on</strong>”, “brains” of your <strong>ABM strategy</strong>. We deliver precise automated analytics at every step of the business process.
                          </Paragraph>
                        </Areas.Block3>
                        <Areas.Block4
                          padding={30}
                          paddingVerticalMd={45}
                          paddingHorizontalMd={65}
                          flex
                          flexDirection={'column'}
                          justifyContent={'center'}
                          color={'#0c1e2c'}
                          style={
                            {
                              background:'#f9f9f9',
                              boxShadow:'rgb(12 30 44 / 15%) 15px 20px 40px -15px'
                            }
                          }
                        >
                          <Paragraph
                            weight={700}
                            
                          >
                            With this approach:
                          </Paragraph>
                          <UL>
                            <li>Marketing has a clear next step for every account in the marketing journey;</li>
                            <li>Sales receives accounts they can confidently take immediate action and close deals; and</li>
                            <li>C-level executives gain visibility and can make decisions with the industry’s first account based lifecycle funnel.</li>
                          </UL>
                        </Areas.Block4>
                      </>
                    )
                  }
                </Composition>
              </Areas.End>
            </>
          )
        }
      </Composition>

    </Section>
  )
}

export default AbmStrategy;

