import React from 'react';
import { Composition,  makeResponsive } from  'atomic-layout';

import { Text } from '../../styledcomponents';

const areas = `
  quote
  author
  
`

const QuoteSlide = ({ text , author}) =>{
  
  return(

      <Composition
        areas={areas}
      >
      { ( Areas ) => (
        <>
          <Areas.Quote
            marginBottom={30}
          >
            <Text
              color={'#0c1e2c'}
              size={20}
              sizeSm={26}
              sizeMd={32}
            >
              { text }
            </Text>
          </Areas.Quote>
          <Areas.Author>
            <Text
              size={16}
              sizeSm={20}
              className="author"
              style={{fontStyle: 'italic'}}
            >
              <strong>{ author }</strong>
            </Text>
          </Areas.Author>
        </>
      )
      }
        
      </Composition>
   
  )
}

export default makeResponsive(QuoteSlide)