import React from 'react';
import styled from 'styled-components';
import { SignInButtonStyle } from '../../styledcomponents';

const StyledMenu = styled.nav `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  background: #0c1e2c;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
  height: 100vh;
  width: 70vw;
  text-align: left;
  padding: 2rem;
  position: absolute;
  
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  button,a {
    font-size: 18px;
    padding: 1rem 0;
    background: none;
    border: none;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s linear;
    text-align:left;
    cursor: pointer;
    &:hover {
      color: #00ccff;
    }
  }
  button.demo{
    display: inline-flex;
    width: auto;
    padding: 0.6em 1.2em !important;
    margin: 1rem 0;
    border-radius: 50px;
    border: 0;
    cursor: pointer;
    background-color: #f63a7a;
    color: #fff;
    &:hover{
      color: #0c1e2c;
      background-color: #f9f9f9;
    }
  }

`

const MobileMenu = ({ open, handleMobileLink,  openSignIn, ...props }) => {
  
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;
  
  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <div
        style={{
          display: 'flex',
          flexDirection:'column',
          marginTop:'auto'
        }}
      >
        <button onClick={()=> handleMobileLink("/", { state: {id:'syncro'} }) } tabIndex={tabIndex}>
          Trends
        </button>
        <button onClick={()=> handleMobileLink("/", { state: {id:'solution'} }) } tabIndex={tabIndex}>
          How We Solve
        </button>
        <button onClick={()=> handleMobileLink("/", { state: {id:'impact'} }) } tabIndex={tabIndex}>
          Customer Success
        </button>
        <button
          className={'demo'} 
          onClick={()=> handleMobileLink("/", { state: {id:'contact'} }) } 
          tabIndex={tabIndex}
        >
          Request Demo
        </button>
        <SignInButtonStyle 
          onClick={openSignIn}
          style={
            { 
              paddingLeft: 0,
              marginRight:'auto',
              fontSize: '18px'
            }
          }
        >
          Sign in
        </SignInButtonStyle>
      </div>
      <button
        style={{marginTop:'auto'}}
        onClick={()=> handleMobileLink("/privacy", { state: {} }) }
        tabIndex={tabIndex}
      >
        Privacy
      </button>
    </StyledMenu>
  )
}

export default MobileMenu;