import styled from 'styled-components';

const StyledBurger = styled.button `
  font-size: 32px;
  color: #fff;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  width: 1.75rem;
  height: 1.75rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  z-index: 10;

  span {
    margin-bottom: 2px;
    width: 1.75rem;
    height: 3px !important;
    background: #fff;
   
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(-20px)' : 'translateX(0)'};
      width:1rem;
    }
    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

const Burger = ({open, setOpen, ...props}) =>{
  const isExpanded = open ? true : false;
  return(
    <StyledBurger 
      aria-label="Toggle Menu" 
      aria-expanded={isExpanded} 
      open={open} 
      onClick={()=> setOpen(!open)}
      {...props}
      >
        <span />
        <span />
        <span />
      </StyledBurger>
  )

}
export default Burger;