import React from 'react';
import { Composition } from 'atomic-layout';
import {Link} from 'react-scroll'
import { RiArrowDownLine } from 'react-icons/ri' 

import { 
  LargeSubHeading, 
  Section,
  InvisiButton
}  from '../styledcomponents';



const areasMobile = `
  .
  heading
  scroll
  .
  `
const areasTablet = `
  . . . .
  heading heading heading .
  . . . scroll
`  

const areasTabletLarge = `
  . . . .
  heading heading heading .
  . . . scroll
`

const areasDesktop = `
  . . . .
  heading heading heading . 
  . . . scroll
`
const areasDesktopLarge = `
  . . . .
  heading heading heading .
  . . . scroll
`
const screenBG = 'https://loominance.com/assets/images/cover-background_05.jpg';

const ScreenOne = () =>{
  
  return(
    <Section 
      className="section" 
      url={screenBG}
     
    >
    <Composition
      className={'content'}
      width={'100%'}
      areas={areasMobile}
      areasSm={areasTablet}
      areasMd={areasTabletLarge}
      areasLg={areasDesktop}
      areasXl={areasDesktopLarge}
      gap={0}
      gapMd={0}
      templateCols={'1fr'}
      templateColsMd={'2fr 0fr repeat(2, 1fr)'}
      templateRows={'3fr 2fr 2fr'}
      templateRowsMd={'4fr 2fr 2fr'}
     
      autoRows={'auto'}
    >
      {(Areas) =>(
        <>
          <Areas.Heading 
            style={{ 
              backgroundColor: '#0c1e2c',
              boxShadow:'0px 0px 300px rgb(161 233 254 / 20%)'
            }}
              padding={30}
              paddingLeft={30}
              paddingSm={30}
              paddingMd={40}
              paddingLg={50}
              paddingHorizontalLg={80}
            
          >
            {/*<ContentHeading color={'#ccc'}>The ABM question</ContentHeading>*/}
            <LargeSubHeading 
              size={32} 
              sizeSm={36} 
              sizeMd={38} 
              sizeLg={44}
              sizeXl={50}
              color={'#fff'}
              lineHeight={1.3}
            >
              How do you know when and how to engage with the right accounts?
            </LargeSubHeading>
          </Areas.Heading>
          <Areas.Scroll
            paddingTop={20}
            flex
            alignItems={'center'}
            justifyContent={'center'}
            style={{ 
              color: '#fff'
            }}
          >
            <InvisiButton
              size={58}
              sizeSm={68}
              sizeMd={78}
              color={'#fff'}
            >
              <Link offset={-100} activeClass="active" to="syncro" smooth={true}>
                <RiArrowDownLine />
             </Link>   
            </InvisiButton>
          </Areas.Scroll>
        </>

      )}
    </Composition>
  </Section>

  )
}

export default ScreenOne