import React from 'react';
import { Link } from "@reach/router";
import { Composition } from 'atomic-layout';

import logo from './assets/LoomDarklogo.png';

const areasMobile = `
  content
`;

const Footer = () =>{
  return(
    <Composition
      className={'footer'}
      areas={areasMobile}
      gap={15}
      gapSm={20}
      gapMd={30}
    >
      {
        (Areas) =>(
          <>
            <Areas.Content 
              flex
              alignItems={'center'}
            
              style={{
               
                background:'transparent',
                padding:'20px',
                width: '100%',
                fontSize: '14px',
                bottom: 0,
                color: '#777',
                }}
              >
              <a href="/" title='Loominance Home'>
              <img 
                style={{ height: '20px', marginRight: '10px'}}
                src={logo} 
                alt='Loominance Brand Logo' 
                /> 
               </a>
                <span 
                  style={{
                    display:'inline-flex',
                    marginRight:'5px'
                  }}
                > ©2021</span> Loominance, Inc.
                <span
                  style={{marginLeft:'auto'}}
                >
                  <Link to="privacy">Privacy and GDPR</Link>
                </span>
            </Areas.Content>
          </>
        )
      }
     
    </Composition>
  )
}

export default Footer;