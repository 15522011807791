import styled from 'styled-components';
import { makeResponsive } from 'atomic-layout';

const OL = styled.ol ` 
  font-weight: ${({ weight }) => weight ? weight: 'initial'}; 
  margin-top: ${({ marginTop }) => marginTop ? marginTop + 'px': 'initial'};
  & >li{
    margin-bottom: 10px
  }
`

export default makeResponsive(OL);