import styled from 'styled-components';
export const DeskTopMenu = styled.ul `

  /* main UL component called: "Menu" */
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
  font-size: 14px;
 
  /* main LI */
  & > li {
    float: left;
    cursor: pointer;
    pointer-events: auto;
    & > a {
      display: inline-block;
      color: white;
      text-align: center;
      padding: 0.6em 0.8em;
      
      text-decoration: none;
      cursor: pointer;
      &:hover {
        background-color: transparent;
      }
    }
    & > a.dropbtn{ 
      position: relative
    }
   
  }

  /* dropdown LI */
  & > .dropdown {
      display: inline-block;
     

      & > .dropdown-content {
        visibility: hidden;
        display: block;
        position: absolute;
        background-color: #f63a7a;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 998;
        opacity: 0;
        transition: opacity  .4s;
        transition-delay: .1s;
        & > a {
          color: #fff;
          padding: 12px 16px;
          text-decoration: none;
          display: block;
          text-align: left;
          cursor: pointer;
          position: relative;
          transition: background-color ease 300ms;
          &:hover {
            background-color: #f95d92;
          }
        }
        & > a:after{
          content: "";
          position: absolute;
          height: 1px;
          width: 30px;
          background: rgba(255,255,255,0.5);
          bottom: 0;
          left: 16px;
        }
        & > a:last-child:after{
          background:transparent;
          height:0;
        }
        &:before{
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 22.5px 20px 22.5px;
          border-color: transparent transparent #f63a7a transparent;
          left: 16px;
          
          top: -6px;
          position: absolute;
          opacity: 1;
        
        }
        
      }

    

      &:hover .dropdown-content {
        visibility: visible;
        opacity: 1;
      }
     
    }
  `
