import React, { useState, useRef} from 'react';
import { useGA4React } from "ga-4-react";
import { useForm } from "react-hook-form"; 
import { Composition, Box } from 'atomic-layout';
import request from 'superagent';

import { ContentSubHeading } from '../../styledcomponents'

const areasForm = `
  header
  form
`

const inputStyles = {
  border: '1px solid #dcdcdc',
  borderRadius: '3px',
  padding: '14px 15px',
  width: '100%',
  fontFamily: 'inherit',
  fontSize: '14px',
  color: '#oc1e2c',
  backgroundColor:'#fff',
  WebkitAppearance:'none'
}
const InputField = ({ label, name, register, errors, required }) => {

  const emailStructure = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const noFreeEmail =  /^([\w-.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)(?!ymail.com)([\w-]+\.)+[\w-]{2,4})?$/;

return(<React.Fragment>
      <div
          style={{
              marginBottom: '15px'
          }}
      >
          <label 
              style={{ 
                  display: 'block',
                  marginBottom: '6px',
                  fontSize: '14px'
              }}
          >
              {
                errors !== undefined && errors[name] ? 
                  <span style={{ fontSize:'14px', color: 'rgb(255 48 95)'}}> 
                    { 
                      errors !== undefined && errors[name].type === 'required' ?   " * required" :
                        errors !== undefined && errors[name].type === 'noFree' ?   " * No free email accounts" : 
                          errors !== undefined && errors[name].type === 'properEmail' ?   " * Need a properly formed email" : null
                    }
                  </span>
                :null
              }
          </label>
          <input
              placeholder={ label  }
              style={inputStyles}
              { 
                ...name === 'entry_1574401609' 
                ? {...register(name, {
                  required: true,
                  validate:{
                      properEmail: v => emailStructure.test(v),
                      noFree: v => noFreeEmail.test(v)
                  },
                 
                })} 
                : {...register(name, { required:  required ? true : false  }) }
              }
              

              />
          
      </div>
  </React.Fragment>
)}
const MessageBox = ({ label, name, register, errors, required }) => {
  return(<React.Fragment>
      <div
          style={{
              marginBottom: '15px'
          }}
      >
          <label 
              style={{ 
                  display: 'block',
                  marginBottom: '6px',
                  fontSize: '14px'
              }}
          >
             
              { 
                  required ? 
                      <span style={{ fontSize:'14px', color: 'rgb(255 48 95)'}}> { errors !== undefined && errors[name] ?   " * required" : ''}</span>
                  : null 
              }
          </label>
          <textarea
              rows={8}
              placeholder={ label }
              style={inputStyles}
            {...register(name, { required: true })} 
            
          />
          
      </div>
  </React.Fragment>
)}
const Submit = () =>(
  <input 
      style={{
          padding: '15px 15px 15px',
          fontWeight:'600',
          width:'100%',
          border: 0,
          backgroundColor: '#0c1e2c',
          color: '#fff',
          fontSize: '16px',
          borderRadius: '4px',
          outline: 'none',
          cursor: 'pointer',
          WebkitAppearance:'none'
      }}
  type='submit'/>
)

const competitorDomains = [
  '6sense', 
  'terminus', 
  'demandbase', 
  'leadspace', 
  'triblio', 
  'dnb', 
  'twilio', 
  'segment', 
  'adobe', 
  'oracle', 
  'openprise', 
  'actionIQ', 
  'loominance'
];

const ContactForm = () =>{
  const ga4 = useGA4React('G-ZQ4BFJSVDT');
  const formRef  = useRef();
  const { register, handleSubmit, formState: { errors }} = useForm();
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ isShowingAlert, setShowingAlert ] = useState(false);
  
  const onSubmit = async (data, e) => { 
    e.preventDefault();
    setIsSubmitting(true);
   
    let slackText = '';
    let newData = {};
    let competitorFlag = false;
    let competitorEmail = '';

    Object.entries(data).forEach(([key, value]) => {
      
      if(key === 'entry_1574401609'){
        if (competitorDomains.some(v => value.includes(v))) {
          competitorFlag = true;
          competitorEmail = value;
        } 
      }

      newData[""+ key.replace('_','.') + ""] = value;
      slackText +=  value + '\n'
    });

    //Slack Notify
    const slackUrl = "https://hooks.slack.com/services/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";
    const slackChannel = "#get_in_touch";
    let message = slackText;

    if(competitorFlag === true){
      let result = await doAutoResponse(competitorEmail);
      message += JSON.parse(result.text).message
    }else{
      message += "No response needed."
    }


    const payload = JSON.stringify({
      "channel": slackChannel,
      "text": message
    });

    request.post(slackUrl)
    .set('Content-type', 'application/x-www-form-urlencoded')
    .send({"payload": payload})
    .catch(err=>{
      console.log('ERR',err);
    })

    
    request
      .post('https://docs.google.com/forms/d/e/1FAIpQLSdVz2kktNvF5WuaVQYji8zm5yVPGC_b8fEb-JAw9dp9flDD7A/formResponse')
      .type('form')
      .send(newData)
      .then(res => {
        //alert('Always error ' + JSON.stringify(res.body));
        setIsSubmitting(false)

      })
      .catch(err=>{
          //console.log('err',err)
          setIsSubmitting(false);
          setShowingAlert(true);
          ga4.event('form_submission', 'contact_form', 'request info')
      })
        
  };

  return(
    <Composition
      areas={areasForm}
      areasMd={areasForm}
      gap={15}
      gapMd={25}
    >
      {(Areas) =>(
        
        <Box
          padding={20}
          paddingSm={30}
          paddingMd={40}
      
        >
          <Areas.Header>
            <Box flex justifyContent={'left'} paddingBottom={20}>
              <ContentSubHeading 
                size={20}
                sizeSm={25}
                sizeMd={36}
                color={'#oc1e2c'}
              > 
                We want to hear from you!
              </ContentSubHeading>
            </Box>
          </Areas.Header>
          <Areas.Form>
            { isSubmitting === true ? 
              <p style={{ borderRadius:'4px',width:'100%',background: '#01b670', color:'#fff', fontSize:'14px', padding: '10px 25px'}}>Sending</p>
              :null
            }
            { isShowingAlert === true ? 
            <div
              className={`alert alert-shown`}
            >
              <strong>Sent!</strong> Thanks for your interest -- we'll be in touch soon!
            </div>
            : null
            }
        
            <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
              <InputField label={'Name *'} name="entry_545501935" errors={errors} register={register} required />
              <InputField 
                label={'Email *'} 
                name="entry_1574401609" 
                errors={errors} 
                register={register}
                required
                
              />
              <InputField label={'Phone'} name="entry_1665239687" errors={errors} register={register} />
              <MessageBox label={'Message *'} name="entry_1951919423" errors={errors} register={register} required />
              <Submit />
            </form>
          </Areas.Form>
        </Box>
      )}
    </Composition>
  )
}
export default ContactForm;

const doAutoResponse = async (emailAddress) =>{
  const api = 'https://uwj7sizn90.execute-api.us-east-1.amazonaws.com/lambdatest';
  const data = { "email" : emailAddress };
  try{
  let response = await request
    .post(api)
    .send(data)
    return response
  }catch(err){
    return err
  }
}