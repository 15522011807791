import React from 'react';
import { Composition } from 'atomic-layout';
import  { useMediaQuery} from '../hooks';
import { 
  Section, 
  ContentHeading, 
  ContentSubHeading, 

  Paragraph
} from '../styledcomponents'

const areas = `
  headings
`;

const areasContent = `
  block1
  block2
`
const areasContentDeskTop = `
  block1 block2
`
const screenBG = 'https://loominance.com/assets/images/cover-background_04.jpg';
const execTriangle = 'https://loominance.com/assets/images/exec-sales-market2x.png';


const Challenge = () =>{
  const isMdWidth = useMediaQuery('(min-width: 500px)');
  return(
    <React.Fragment>
    <Section 
      bgcolor={'#fff'}
      url={screenBG}
      style={{ borderTop: '1px solid #eee'}}
    >
    <Section
      id=""
      bgcolor={'transparent'}
    >
      <Composition
        className={'content'}
        areas={areas}
        gap={0}
        templateCols={'1fr'}
        padding={30} 
        paddingSm={35} 
        paddingMd={45} 
        paddingTopMd={90}
        paddingBottomMd={10}
        paddingHorizontalLg={80}
        paddingTopLg={120}
        paddingBottomLg={20}
      >
        {
          (Areas)=>(
            <>
              <Areas.Headings widthLg={800}>
                <ContentHeading
                  size={16}
                  sizeMd={20}
                  sizeLg={24}
                  weight={'400'}
                  transform={'uppercase'}
                >
                  Trends
                </ContentHeading>
                <ContentSubHeading
                  size={30}
                  sizeSm={30}
                  sizeMd={40}
                  sizeLg={45}
                  sizeXl={55}
                  marginBottom={20}
                  marginBottomMd={40}
                  color={'#0c1e2c'}
                >
                  How Loominance helps.
                </ContentSubHeading>
              </Areas.Headings>
           
            </>
          )
        }
      </Composition>
      </Section>
      <Section
  bgcolor={'transparent'}
      >
      <Composition
        className={'content'}
        areas={areasContent}
        areasLg={areasContentDeskTop}
        paddingBottom={45}
        paddingBottomMd={90}
        paddingBottomLg={120}
        templateCols={'1fr'}
        templateColsLg={'2fr 2fr'}
      >
        {
          (Areas)=>(
            <>
              <Areas.Block1
                padding={30} 
                paddingSm={35} 
                paddingMd={45} 
                paddingTopMd={90}
                paddingBottomMd={90}
                paddingHorizontalLg={80}
                paddingTopLg={120}
                paddingBottomLg={120}
                style={{background: '#f6f6f6'}}
                
              >
                  <img 
                    alt={''}
                    style={{maxWidth:  isMdWidth ? '400px': '300px', display:'block', margin: '0 auto' }} 
                    src={execTriangle} 
                  />
                 
              </Areas.Block1>
              <Areas.Block2
                padding={30} 
                paddingSm={35} 
                paddingMd={45} 
                paddingHorizontalLg={80}
                flex
                flexDirection={'column'}
                justifyContent={'center'}
                style={
                  { 
                    boxShadow:'rgb(12 30 44 / 15%) 15px 20px 30px -15px',
                    background:'#fff'
                  }
                }
              >
                <Paragraph>
                <strong>The challenge today is that marketing is not aligned with c-level executives and sales.</strong> Marketing goals and metrics remain tactical and transactional where sales has always been focused on accounts. This generates the notion that marketing does not do enough to support the sales engine.
                </Paragraph>
                <p>Loominance is changing all this with a strategy that provides sales, marketing, and c-level executives what they need to be successful.</p>
              </Areas.Block2>
            </>
          )
        }
      </Composition>
      </Section>
      </Section>
      </React.Fragment>
  )
}

export default Challenge;