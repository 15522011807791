import React from 'react'
import { Router } from "@reach/router";

import { NotFound } from './screens';
import { HomePage, PrivacyPage } from './pages'

const App = () =>{
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }
  return(
  <Router>
    <NotFound default />
    <HomePage path="/" />
    <PrivacyPage path="privacy" />
  </Router>)
   
  }
export default App;


/*
<ReactFullpage
        licenseKey={'15466D17-62544DFE-AF46EFB8-B4F54CE3'}
        navigation={true}
        verticalCentered={false}
        navigationTooltips={['Welcome', 'No Sure', 'Get in Touch']}
        render={({ state, fullpageApi }) =>{
          return(
            <ReactFullpage.Wrapper>
              <ScreenOne />
              <Problem />
              <ScreenTwo>
                <Footer />
              </ScreenTwo>
              
            </ReactFullpage.Wrapper>
*/