import React from 'react';
import { Composition } from 'atomic-layout';

import { LoomFeatureList } from '../components/card';
import { ContentHeading, ContentSubHeading, Section}  from '../styledcomponents';

const areasMobile = `
  heading
  list
`;

const loomUser = 'https://loominance.com/assets/images/loom-user.png'
const screenBG = 'https://loominance.com/assets/images/cover-background_02.jpg';

const data= [
  { id:'For humans and machines',text:'Loominance automates the end-to-end process of collecting, capturing, enriching, correlating, and transforming all of the pieces of data about your accounts into both intelligent machine and human consumable knowledge at machine scale.', imageUrl:''},
  { id:'78', text:'', imageUrl: loomUser}, 
  { id:'Reusable and adaptable',text:'But we don’t stop there. We also provide – through intelligent automation – reusable and adaptable leading practice analytic pipelines. These pipelines consistently operate your strategies like ABM and other common activations that sales and marketing go to market functions require.'},
  { id:'Automated',text: 'Loominance automates this entire process, enabling analysts to recapture significant amounts of time–time they can refocus on creating new leading practice pipelines and driving better business outcomes.'},

  { id:'Hundreds of data sources', text:'By incorporating hundreds of data sources in real time, Loominance creates an ever-growing library of leading practice analytics capabilities, intelligent data that lends itself to consistent, easier, practically limitless, and automated analytics of your data.'}, 
  { id:'Cloud Native', text:'Loominance lives in the cloud  – simply plug into all of its capability to understand all of your accounts. No deployment, special training or skills needed, beyond the business rules and processes that you follow. Simply connect and watch the waves of new efficiency and creativity of your entire go to market organization.'}, 
]
const ScreenTwo = ({children}) =>{
  return(
    <React.Fragment>
      <Section 
        id={'solution'}
        className="section"
        url={screenBG}    
      >

        <Composition
          className={'content'}
          areas={areasMobile}
        
          gap={0}
          gapMd={0}
          gapRow={0}
          padding={30} 
          paddingSm={35} 
          paddingMd={45} 
          paddingVerticalMd={90}
          paddingHorizontalLg={80}
          paddingVerticalLg={120}
        >
          {(Areas) => (
            <>
              
              <Areas.Heading
                style={{ color: '#fff'}}
                widthLg={800}
              >
                <ContentHeading
                  size={16}
                  sizeMd={20}
                  sizeLg={24}
                  weight={'400'}
                  transform={'uppercase'}
                  color={'#fff'}
                >
                 How We Solve
                </ContentHeading>
                <ContentSubHeading
                  size={30}
                  sizeSm={30}
                  sizeMd={40}
                  sizeLg={45}
                  sizeXl={55}
                  color={'#fff'}
                  marginBottom={20}
                  marginBottomSm={28}
                  marginBottomMd={36}
                  marginBottomLg={50}
                >
                  Welcome to the Modern Automated Customer Intelligence Cloud.
                </ContentSubHeading>
          
              </Areas.Heading>
              <Areas.List>
                <LoomFeatureList data={data} />
              </Areas.List>
             
            </>

          )}
        </Composition>
        { children }
      </Section>
     
    </React.Fragment>
  )
}

export default ScreenTwo