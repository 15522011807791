import styled from 'styled-components';

const Card = styled.div`
  display: inline-block;
  background-color: ${({bgcolor}) => bgcolor ? bgcolor : '#0c1e2c'};
  color: ${({color}) => color ? color : '#fff'};
  border-radius: 0px;
  box-shadow: ${({boxShadow}) => boxShadow ? '0 18px 25px -15px rgba(12,30,44, 0.2)' : 'none'};
`

export default Card;