import React from 'react';
import { Composition } from 'atomic-layout';

import { ContactForm } from  '../components/forms'
import { ContentHeading, Section}  from '../styledcomponents';

const areasSlide2Mobile = `
  largeHeading
  contactForm
  footer
`;

const screenBG = 'https://loominance.com/assets/images/cover-background_04.jpg';

const Contact = ({children}) =>{
  return(
    <React.Fragment>
      <Section 
        id={'contact'}
        className="section"
        url={screenBG}    
        style={{
          position: 'relative',
          zIndex: 0
        }}
      >
        <Composition
          areas={areasSlide2Mobile}
          areasMd={areasSlide2Mobile}
          gap={0}
          gapMd={0}
          gapRow={0}
          templateCols={'1fr'}
          templateColsSm={'1fr'}
          templateColsMd={'700px'}
          marginRight={'auto'}
          marginLeft={'auto'}
          justifyContent={'center'}
          paddingBottom={30}
          paddingBottomSm={45}
          paddingBottomMd={55}
        >
          {(Areas) => (
            <>
              
              <Areas.LargeHeading 
                padding={45} 
                paddingBottom={10}
                paddingSm={65} 
                paddingBottomSm={20}
                paddingMd={85} 
                paddingBottomMd={20}
                justifyContent={'center'}
              >
                <ContentHeading
                  size={30}
                  sizeSm={30}
                  sizeMd={40}
                  sizeLg={45}
                  sizeXl={55}
                  color={'#0c1e2c'}
                  textAlign={'center'}
                >
                  Get in 
                  touch
                </ContentHeading>
              </Areas.LargeHeading>
              <Areas.ContactForm>
                <ContactForm />
              </Areas.ContactForm>
            </>

          )}
        </Composition>
        { children }
      </Section>
     
    </React.Fragment>
  )
}

export default Contact