import React from 'react';
import { Composition } from 'atomic-layout';

import { 
  Section, 
  ContentHeading, 
  ContentSubHeading, 
  ResponsiveImage,
  Paragraph,
  UL
} from '../styledcomponents'

const areas = `
  headings
`;

const areasContent = `
  block1
  block2
`
const areasContentDeskTop = `
  block1 block2
`

//const screenBG = 'https://loominance.com/assets/images/cover-background_01.jpg';

const elephantRoom = 'https://loominance.com/assets/images/elephant-room.png';

const ElephantRoom = ({children}) =>{

  return(
    <React.Fragment>
    <Section
      id=""
      bgcolor={'#fff'}
      style={{ borderTop: '1px solid #eee'}}
    >
      <Composition
        className={'content'}
        areas={areas}
        gap={0}
        templateCols={'1fr'}
        padding={30} 
        paddingSm={35} 
        paddingMd={45} 
        paddingTopMd={90}
        paddingBottomMd={10}
        paddingHorizontalLg={80}
        paddingTopLg={120}
        paddingBottomLg={20}
      >
        {
          (Areas)=>(
            <>
              <Areas.Headings widthLg={800}>
                <ContentHeading
                  size={16}
                  sizeMd={20}
                  sizeLg={24}
                  weight={'400'}
                  transform={'uppercase'}
                >
                  Trends
                </ContentHeading>
                <ContentSubHeading
                  size={30}
                  sizeSm={30}
                  sizeMd={40}
                  sizeLg={45}
                  sizeXl={55}
                  marginBottom={0}
                  marginBottomMd={40}
                  color={'#0c1e2c'}
                >
                  The elephant in the room.
                </ContentSubHeading>
              </Areas.Headings>
           
            </>
          )
        }
      </Composition>
      </Section>
      <Section
        bgcolor={'#fff'}
      >
      <Composition
        className={'content'}
        areas={areasContent}
        areasLg={areasContentDeskTop}
      
        paddingBottomMd={90}
        paddingBottomLg={120}
        templateCols={'1fr'}
        templateColsLg={'2fr 2fr'}
      >
        {
          (Areas)=>(
            <>
              <Areas.Block1
                padding={30} 
                paddingTop={0}
                paddingSm={35} 
                paddingMd={45} 
                paddingTopMd={0}
                paddingBottomMd={60}
                paddingHorizontalLg={80}

              >
                  <Paragraph
                    weight={600}
                    marginBottom={40}
                    marginTop={0}
                  >
                    While Marketing teams have seen some modest results from ABM platforms in production - they still struggle to justify the MarTech investment to leadership.
                  </Paragraph>
                  <p>Sales has always been account centric but as a marketer are you tracking where accounts are along their journey?</p>
                  <p>The key is precise account prioritization. Deliver quality insights over volume of information.</p>
                  <p><strong>Across net new and existing accounts, marketers need to:</strong></p>
                  <UL 
                    marginTop={10}
                    marginTopMd={15}
                    style={{color:'#0c1e2c'}}
                  >
                    <li>Coordinate any and all relevant data to allow precise,high value activations.</li>
                    <li>Agree on the how to prioritize accounts to ensure alignment with sales.</li>
                    <li>Apply your proven business rules to get the consistent results you expect and enable rapid adoption.</li>
                  </UL>
              </Areas.Block1>
              <Areas.Block2
                padding={30} 
                paddingRight={0}
                paddingSm={35} 
                paddingMd={45} 
                paddingTopMd={90}
                paddingBottomMd={60}
                paddingHorizontalLg={80}
                paddingRightSm={0}
                paddingRightMd={0}
                paddingRightLg={0}
                style={{background: '#0c1e2c'}}
              >
                <ResponsiveImage 
                  maxWidth={300}
                  maxWidthLg={350}
                  maxWidthXl={420}
                  margin={'0 0 0 auto'}
                  src={elephantRoom}  
                  alt={''}
                />
                
              </Areas.Block2>
            </>
          )
        }
      </Composition>
      </Section>
      </React.Fragment>
  )
}

export default ElephantRoom;