import React,{ useEffect} from 'react';
import { useGA4React } from "ga-4-react";

//Local App
import Header from '../Header';
import Footer from '../Footer';

const Basepage = ({ children }) =>{
  
  const ga4 = useGA4React('G-ZQ4BFJSVDT');
  useEffect( () =>{
    if(ga4 !== undefined){
      //console.log('window.location.pathname',window.location.pathname);
      ga4.pageview(document.title, window.location, window.location.pathname, 'G-ZQ4BFJSVDT')
    }
  },[ga4])
  return(
    <React.Fragment>
      <Header />
        { children }
      <Footer />
    </React.Fragment>
  )
}
export default Basepage;