import React,{ useEffect} from 'react';
import { useGA4React } from "ga-4-react";
import { Composition }  from 'atomic-layout'

//Local App
import Header from '../Header';
import Footer from '../Footer';

import { 
  ContentHeading, 
  ContentSubHeading,
  Section,
}  from '../styledcomponents';

const areasMobile = `
  .
  heading
  scroll
  .
  `

const NotFound = () =>{
  const ga4 = useGA4React('G-ZQ4BFJSVDT');
  useEffect( () =>{
    if(ga4 !== undefined){
    ga4.pageview(document.title, window.location, window.location.pathname, 'G-ZQ4BFJSVDT')
    }
  },[ga4]);

  return(
    <React.Fragment>
      <Header />
      <Section
        className="section"
        bgcolor={'#0c1e2c'}
       
      >
      <Composition
        className={'content'}
        areas={areasMobile}
        templateCols={'1fr'}
        padding={30} 
        paddingSm={35} 
        paddingVertical={60}
        paddingVerticalSm={70}
        paddingMd={45} 
        paddingVerticalMd={90}
        paddingHorizontalLg={80}
        paddingVerticalLg={120}  
      >
        {
          (Areas) =>(
            <>
              <Areas.Heading>
              <ContentHeading
                  size={16}
                  sizeMd={20}
                  sizeLg={24}
                  weight={'400'}
                  transform={'uppercase'}
                  color={'#fff'}
                >
                 404
                </ContentHeading>
                <ContentSubHeading
                  size={30}
                  sizeSm={30}
                  sizeMd={40}
                  sizeLg={45}
                  sizeXl={55}
                  color={'#fff'}
                  marginBottom={20}
                  marginBottomSm={28}
                  marginBottomMd={36}
                  marginBottomLg={50}
                >
                  Not Found
                </ContentSubHeading>
              </Areas.Heading>
            </>
          )
        }
      </Composition>
      </Section>
      <Footer />
    </React.Fragment>
  )
}
export default NotFound;