import React, { useState, useRef} from 'react';
import { useForm } from "react-hook-form"; 
import { Composition, Box } from 'atomic-layout';

import logo from '../../assets/LoomDarklogo.png';

const areasForm = `
  header
  form
`
const InputField = ({ label, name, register, errors, required }) => {
  return(<React.Fragment>
      <div
          style={{
              marginBottom: '15px'
          }}
      >
          <label 
              style={{ 
                  display: 'block',
                  marginBottom: '6px',
                  fontSize: '14px'
              }}
          >
             
              { 
                  required ? 
                      <span style={{ fontSize:'14px', color: '#ee478e'}}> { errors !== undefined && errors[name] ?   " * required" : ''}</span>
                  : null 
              }
          </label>
          <input
              placeholder={ label }
              style={{
                  border: '1px solid #ddd',
                  borderRadius: '3px',
                  padding: '10px 15px',
                  width: '200px',
                  fontFamily: 'inherit',
                  fontSize: '14px',
                  color: 'inherit'
              }}
            {...register(name, { required: true })} />
          
      </div>
  </React.Fragment>
)}
const Submit = () =>(
  <input 
      style={{
          padding: '8px 15px 7px',
          border: 0,
          backgroundColor: '#f63a7a',
          color: '#fff',
          fontSize: '14px',
          borderRadius: '100px',
          outline: 'none',
          cursor: 'pointer'
      }}
  type='submit'/>
)
const SignIn = () =>{
  const formRef  = useRef();
  const { register, handleSubmit, formState: { errors }} = useForm();
  const [ isValid, setIsValid ] = useState(null)
  
  const onSubmit = async (data, e) => { 
    e.preventDefault();
    setIsValid(false);
  };

  return(
    <div
      style={{       
         boxShadow  : '0 1.66667rem 2.5rem 0 rgba(18,26,72,.08)',
         background : '#fff',
         padding    : '30px'
      }}
    >
    <Composition
      areas={areasForm}
      areasMd={areasForm}
      gap={15}
      gapMd={25}
    >
      {(Areas) =>(
        <>
          <Areas.Header>
            <Box flex justifyContent={'center'}>
              <img style={{ maxWidth: '50px'}} src={logo} alt='Loominance Brand Logo' />
            </Box>
          </Areas.Header>
          <Areas.Form>
          { isValid === false ? 
            <p style={{color:'#ff5573', fontSize:'14px'}}>Invalid Login</p>
            :null
          }
            <form id="sign_in_form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
              <InputField label={'Username'} name="entry_545501935" errors={errors} register={register} required />
              <InputField label={'Password'} name="entry_1201123187" errors={errors} register={register} required />
              <Submit />
            </form>
          </Areas.Form>
        </>
      )}
    </Composition>
    </div>
  )
}
export default SignIn;