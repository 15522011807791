import React from 'react';
import { Composition } from 'atomic-layout';

import { ContentSubHeading, Section, Text } from '../styledcomponents'

const areas= `
  heading
  content
`;
const areasLg= `
  heading content
`;

const screenBG = 'https://loominance.com/assets/images/cover-background_04.jpg'

const  AreYouSyncro = ({children}) =>{

  return(
    <Section
      id="syncro"
      url={screenBG}
    >
      <Composition
        className={'content'}
        areas={areas}
        areasLg={areasLg}
        gap={0}
        templateCols={'1fr'}
        templateColsLg={'3fr 4fr'}
        padding={30} 
        paddingSm={35} 
        paddingMd={45} 
        paddingVerticalMd={90}
        paddingHorizontalLg={80}
        paddingVerticalLg={120}
      >
        {
          (Areas) =>(
            <>
              <Areas.Heading
                padding={30} 
                paddingSm={35} 
                paddingMd={45} 
                style={{ backgroundColor:'#0c1e2c'}}
              >
                <ContentSubHeading
                  color={'#fff'}
                  size={30}
                  sizeSm={30}
                  sizeMd={40}
               
                >
                  Are all functions of your Go To Market together?
                </ContentSubHeading>
              </Areas.Heading>
              <Areas.Content
                padding={30} 
                paddingSm={35} 
                paddingMd={45} 
                style={{
                  backgroundColor:'rgba(255,255,255,0.9)',
                  boxShadow:'15px 20px 30px -15px rgb(12 30 44 / 15%)'
                }}
              >
                <Text
                  size={18}
                  sizeMd={24}
                  lineHeight={1.5}
                >
                <p style={{marginTop: 0}}>Specifically, how well is marketing supporting this motion?</p>  
                <p>Are you aligned including C-suite execs, sales, marketing and channel functions – aligned on “what information you’re tracking and what success looks like?”</p>  
                Do you know how to bring the right accounts into your market?
                </Text>
              </Areas.Content>
            </>
          )
        }
      </Composition>
    </Section>
  )
}

export default AreYouSyncro