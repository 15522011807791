import React from 'react';
import { Composition, Box } from 'atomic-layout';

import { Section, Text} from '../styledcomponents'

const areasQuote = `
  quote
  author
  action
  
`

const data= [
  {text:'By end 2025 ABM will be the main way organizations identify, plan, manage and measure their Sales motion', author: 'Forrester, 2020 ABM Report'}
]

const SingleQuote = ({children}) =>{

  return(
    <Section
      id=""
      background={'#fff'}
    >
      <Composition
        className={'content'}
        style={{maxWidth: '1000px', position:'relative', zIndex: 4}}
        areas={areasQuote}
        gap={0}
        templateCols={'1fr'}
        padding={30} 
        paddingSm={35} 
        paddingVertical={60}
        paddingVerticalSm={70}
        paddingMd={45} 
        paddingVerticalMd={90}
        paddingHorizontalLg={80}
        paddingVerticalLg={120}   
      > 
      {
        (Areas) =>(
          <>
            <Areas.Quote flex  marginBottom={30}>
              <Text
                size={24}
                sizeSm={36}
                sizeMd={42}
              >
                "{ data[0].text }"
              </Text> 
            </Areas.Quote>
            <Areas.Author>
              <Text
                className="author"
                style={{fontStyle: 'italic'}}
              >
                <strong>{ data[0].author }</strong>
              </Text>
            </Areas.Author>
            <Areas.Action
              flex
              justifyContent={'center'}
              marginTop={60}
              paddingTop={30}
              
            >
              <Box
                className="curveShadow"
                style={{
                  background:'#0c1e2c',
                  position: 'relative',
                  borderTopLeftRadius: '30px',
                  borderBottomRightRadius: '30px',
                }}
                 padding={20}
                 paddingMd={30}

              >
                  <Text
                    color={'#a1e9ff'}
                    weight={600}
                    size={18}
                    sizeSm={24}
                  >
                    A Shift to Account Centricity
                  </Text>
              </Box>
            </Areas.Action>
          
          </>
        )
      }
       
      </Composition>
    </Section>
  )
}

export default SingleQuote;